/* eslint-disable no-unused-vars */
import { IUserProvider, IUserProviderUser } from 'src/types/provider'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class UserProviderService extends CRUDService<IUserProvider> {
  protected apiPath = 'api/app/user-provider'

  getCurrentProvider() {
    return instanceApi.get<string>(`${this.apiPath}/provider-id`)
  }

  getProviderUsers() {
    return instanceApi.get<IUserProviderUser>(`${this.apiPath}/users`)
  }

  getProviderWithAddress() {
    return instanceApi.get<IUserProviderUser>(
      `${this.apiPath}/provider-with-address`
    )
  }

  getProviderUserSettings(providerId: string) {
    return instanceApi.get<IUserProviderUser>(
      `${this.apiPath}/by-provider-id/${providerId}`
    )
  }

  createProviderUser(request: IUserProviderUser) {
    return instanceApi.post<IUserProviderUser>(`${this.apiPath}/user`, request)
  }

  updateProviderUser(request: IUserProviderUser) {
    return instanceApi.put<IUserProviderUser>(
      `${this.apiPath}/user-provider`,
      request
    )
  }

  revokeProviderUser(userId: string) {
    return instanceApi.post<IUserProviderUser>(
      `${this.apiPath}/revoke-user-access`,
      {
        revokeAccess: true,
        userId: userId
      }
    )
  }
}
